import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Util } from './util';
import { Component, Input } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Location } from '@angular/common';
import * as CryptoJS from 'crypto-js'; // Import crypto-js


@Injectable()
export class HttpServer {

  private apiUrl = 'https://us-west2-deals-automation-development.cloudfunctions.net/GetAuthToken';
  private client_id = '727701302357';
  private client_secret = 'c6963e54fa4a50cd25340db7f1716c715d4042e321dc6bbaf204e9862455403d';


  constructor(
    private util: Util,
    private loc: Location
  ) {
  }

  SendData(
    model: any,
    url: any,
    mode: string,
    isBannerNeed = true,
    isDirectUrl = false,
    token: any =null ,
    bannerId='1',
  ) {

    if (isDirectUrl === true) {
      url = url;
    }
    else {
      const IsInvoiceUrl = environment.InvoiceUrl.find((x: any) => x.toString().toLowerCase() === url.toString().toLowerCase())
      if (this.util.isProd()) {
        url = (this.util.IsNotNull(IsInvoiceUrl) ?
          environment.prodShoreInvoiceAPI : environment.prodShoreAPI) + '/' + url;
      }
      else if (this.util.isUAT()) {
        url = (this.util.IsNotNull(IsInvoiceUrl) ?
          environment.uatShoreInvoiceAPI : environment.uatShoreAPI) + '/' + url;
      }
      else if (this.util.isDev()) {
        url = (this.util.IsNotNull(IsInvoiceUrl) ?
          environment.devShoreInvoiceAPI : environment.devShoreAPI) + '/' + url;
      }
      else {
        url = (this.util.IsNotNull(IsInvoiceUrl) ?
          environment.devShoreInvoiceAPI : environment.devShoreAPI) + '/' + url;

        // url = (this.util.IsNotNull(IsInvoiceUrl) ?
        // environment.offShoreInvoiceAPI : environment.offShoreAPI) + '/' + url;

        //url = environment.devShoreAPI + '/' + url;
        //url = environment.OffShoreAPI + '/' + url;
      }
    }

    let headers: any;
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: '',
      Banner: ''
    };

    if (url.includes("7py8fahnf3") || url.includes("llz1npmgm7")) {
      if (bannerId != null && bannerId.toString() == "2") {
        headers.Banner = 'lucky';
      }
      else if (bannerId != null && bannerId.toString() == "3") {
        headers.Banner = 'foodmaxx';
      }
      else {
        headers.Banner = 'savemart';
      }
    }

    if (!this.util.IsNotEmpty(headers.Banner)) {
      delete headers.Banner
    }
    if (!this.util.IsNotEmpty(token)) {
      token = this.util.getToken();
    }

    if (this.util.IsNotEmpty(token)) {
      headers.Authorization = 'Bearer ' + token;
    }

    if (!this.util.IsNotEmpty(headers.Authorization)) {
      delete headers.Authorization;
    }
    // delete headers.Authorization;

    return fetch(url, {
      method: mode ? mode.toUpperCase() : 'GET',
      headers,
      body: model ? JSON.stringify(model) : null,
    }).
      then((Response) => Response.clone().json()).
      catch(function () {
        console.log("error");
      });
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }



  generateNonce(): string {
    try {
      const array = new Uint8Array(16);  // Use Uint8Array to generate random values
      window.crypto.getRandomValues(array);  // Populate array with random values
  
      // Convert the random array to a string and base64 encode it
      const nonce = btoa(String.fromCharCode(...array)).replace(/=+$/, ''); 
      return nonce;
    } catch (error) {
      throw error;  // Throw error so it's caught in higher-level logic
    }
  }
        
  generateSignature(timestamp: string, nonce: string): string {
    const stringToSign = `${this.client_id}:${timestamp}:${nonce}`;
    const signature = CryptoJS.HmacSHA256(stringToSign, this.client_secret).toString(CryptoJS.enc.Base64); // HMAC-SHA256 signature
    return signature;
  }
  
  getAuthToken() {
  
    const timestamp = Math.floor(Date.now() / 1000).toString();
    const nonce = this.generateNonce();
    const signature = this.generateSignature(timestamp, nonce); 
  
    const credentials = btoa(`${this.client_id}:${this.client_secret}`);

    const headers = {
      'Authorization': `Basic ${credentials}`,
      'timestamp': timestamp,
      'nonce': nonce,
      'signature': signature
    };
  
  return fetch(this.apiUrl, { headers })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch token: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => data.bearer) // Use `data.bearer` to get the token
      .catch(error => {
        console.error('Error fetching auth token:', error);
        throw error;
      });
  }
    
}
